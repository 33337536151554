// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.5s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		4px,
		border-width:		1px,
		element-height:		3.5em,
		element-margin:		2em,
		gutter:				3em,
		inner: 				80em
	);

// Font.
	$font: (
		family:				('Open Sans', Arial, Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		400
	);

// Palette.
	$palette: (
		bg:					#ffffff,
		fg:					#5a5a5a,
		fg-bold:			#333333,
		fg-light:			#aaaaaa,
		border:				rgba(144,144,144,0.25),
		border-bg:			rgba(144,144,144,0.075),
		border2:			rgba(144,144,144,0.5),
		border2-bg:			rgba(144,144,144,0.2),
		highlight:			accent1,

		accent1: (
			bg:				#40AF6B,
			fg:				rgba(255,255,255,0.75),
			fg-bold:		#ffffff,
			fg-light:		rgba(255,255,255,0.5),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent2: (
			bg:				#5a5a5a,
			fg:				rgba(255,255,255,0.75),
			fg-bold:		#ffffff,
			fg-light:		rgba(255,255,255,0.5),
			border:			rgba(255,255,255,0.15),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent3: (
			bg: 			#4017A4,
			fg:				rgba(255,255,255,0.75),
			fg-bold:		#ffffff,
			fg-light:		rgba(255,255,255,0.5),
			border:			rgba(255,255,255,0.15),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		)
	);

	// Extra colors

	$color1: #009FE3;
	$color2: #C4CC05;
	$color3: #BD1C3B;
	$color4: #FEC202;